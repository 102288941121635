@use "@angular/material" as mat;
@use "material-theme-helper.scss";
@import "@angular/material/theming";
@include mat.core();
$ap-typography: mat.define-typography-config(
  $font-family: $font-family,
  $headline-1:
    mat.define-typography-level(
      $font-weight: 300,
      $font-size: 6rem,
      $line-height: 112px,
      $letter-spacing: -1.5px,
    ),
  $headline-2:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 300,
      $font-size: 3.75rem,
      $line-height: 72px,
      $letter-spacing: -0.5px,
    ),
  $headline-3:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 2.8125rem,
      $line-height: 3.5rem,
      $letter-spacing: normal,
    ),
  $headline-4:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 2.125rem,
      $line-height: 2.25rem,
      $letter-spacing: normal,
    ),
  $headline-5:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 1.5rem,
      $line-height: 1.5rem,
      $letter-spacing: 0.18px,
    ),
  $headline-6:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 1.25rem,
      $line-height: 2.25rem,
      $letter-spacing: 0.15px,
    ),
  $body-1:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5rem,
      $letter-spacing: 0.5px,
    ),
  $body-2:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 0.875rem,
      $line-height: 1.25rem,
      $letter-spacing: 0.25px,
    ),
  $subtitle-1:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 1rem,
      $line-height: 1.5rem,
      $letter-spacing: 0.15px,
    ),
  $subtitle-2:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 500,
      $font-size: 0.875rem,
      $line-height: 1.25rem,
      $letter-spacing: 0.1px,
    ),
  $caption:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 400,
      $font-size: 0.75rem,
      $line-height: 1rem,
      $letter-spacing: 0.4px,
    ),
  $button:
    mat.define-typography-level(
      $font-family: $font-family,
      $font-weight: 500,
      $font-size: 0.875rem,
      $line-height: 1.375rem,
      $letter-spacing: 0.5px,
    ),
);
@layer utilities {
  .ap-typography-headline-1 {
    @include mat.typography-level($ap-typography, "headline-1");
  }
  .ap-typography-headline-2 {
    @include mat.typography-level($ap-typography, "headline-2");
  }
  .ap-typography-headline-3 {
    @include mat.typography-level($ap-typography, "headline-3");
  }
  .ap-typography-headline-4 {
    @include mat.typography-level($ap-typography, "headline-4");
  }
  .ap-typography-headline-5 {
    @include mat.typography-level($ap-typography, "headline-5");
  }

  .ap-typography-headline-6 {
    @include mat.typography-level($ap-typography, "headline-6");
  }

  .ap-typography-subtitle-1 {
    @include mat.typography-level($ap-typography, "subtitle-1");
  }
  .ap-typography-subtitle-2 {
    @include mat.typography-level($ap-typography, "subtitle-2");
  }
  .ap-typography-body-1 {
    @include mat.typography-level($ap-typography, "body-1");
  }
  .ap-typography-body-2 {
    @include mat.typography-level($ap-typography, "body-2");
  }
  .ap-typography-caption {
    @include mat.typography-level($ap-typography, "caption");
  }
  .ap-typography-button {
    @include mat.typography-level($ap-typography, "button");
  }
}
.ap-typography-headline-1 {
  @include mat.typography-level($ap-typography, "headline-1");
}
.ap-typography-headline-2 {
  @include mat.typography-level($ap-typography, "headline-2");
}
.ap-typography-headline-3 {
  @include mat.typography-level($ap-typography, "headline-3");
}
.ap-typography-headline-4 {
  @include mat.typography-level($ap-typography, "headline-4");
}
.ap-typography-headline-5 {
  @include mat.typography-level($ap-typography, "headline-5");
}

.ap-typography-headline-6 {
  @include mat.typography-level($ap-typography, "headline-6");
}

.ap-typography-subtitle-1 {
  @include mat.typography-level($ap-typography, "subtitle-1");
}
.ap-typography-subtitle-2 {
  @include mat.typography-level($ap-typography, "subtitle-2");
}
.ap-typography-body-1 {
  @include mat.typography-level($ap-typography, "body-1");
}
.ap-typography-body-2 {
  @include mat.typography-level($ap-typography, "body-2");
}
.ap-typography-caption {
  @include mat.typography-level($ap-typography, "caption");
}
.ap-typography-button {
  @include mat.typography-level($ap-typography, "button");
}
$ap-primary-palette: (
  50: var(--primary-palette-50),
  100: var(--primary-palette-100),
  200: var(--primary-palette-200),
  300: var(--primary-palette-300),
  400: var(--primary-palette-400),
  500: var(--primary-palette-500),
  600: var(--primary-palette-600),
  700: var(--primary-palette-700),
  800: var(--primary-palette-800),
  900: var(--primary-palette-900),
  A100: var(--primary-palette-A100),
  A200:  var(--primary-palette-A200),
  A400:  var(--primary-palette-A400),
  A700:  var(--primary-palette-A700),
  contrast: (
    50: var(--primary-palette-contrast-50),
    100: var(--primary-palette-contrast-100),
    200: var(--primary-palette-contrast-200),
    300: var(--primary-palette-contrast-300),
    400: var(--primary-palette-contrast-400),
    500: var(--primary-palette-contrast-500),
    600: var(--primary-palette-contrast-600),
    700: var(--primary-palette-contrast-700),
    800: var(--primary-palette-contrast-800),
    900: var(--primary-palette-contrast-900),
    A100: var(--primary-palette-contrast-A100),
    A200:  var(--primary-palette-contrast-A200),
    A400: var(--primary-palette-contrast-A400),
    A700: var(--primary-palette-contrast-A700),
  ),
);
$ap-warn-palette: (
  50: var(--warn-palette-50),
  100: var(--warn-palette-100),
  200: var(--warn-palette-200),
  300: var(--warn-palette-300),
  400: var(--warn-palette-400),
  500: var(--warn-palette-500),
  600: var(--warn-palette-600),
  700: var(--warn-palette-700),
  800: var(--warn-palette-800),
  900: var(--warn-palette-900),
  A100: var(--warn-palette-A100),
  A200: var(--warn-palette-A200),
  A400: var(--warn-palette-A400),
  A700: var(--warn-palette-A700),
  contrast: (
    50:  var(--warn-palette-contrast-50),
    100: var(--warn-palette-contrast-100),
    200: var(--warn-palette-contrast-200),
    300: var(--warn-palette-contrast-300),
    400: var(--warn-palette-contrast-400),
    500: var(--warn-palette-contrast-500),
    600: var(--warn-palette-contrast-600),
    700: var(--warn-palette-contrast-700),
    800: var(--warn-palette-contrast-800),
    900: var(--warn-palette-contrast-900),
    A100: var(--warn-palette-contrast-A100),
    A200: var(--warn-palette-contrast-A200),
    A400: var(--warn-palette-contrast-A400),
    A700: var(--warn-palette-contrast-A700),
  ),
);




.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-button,
.mat-stroked-button,
.mat-flat-button,
.mat-raised-button {
  &.btn-sm {
    padding: 0.3125rem 0.625rem !important;
  }
}

$ap-primary: mat.define-palette($ap-primary-palette);
$ap-danger: mat.define-palette($ap-warn-palette);
$ap-theme-light: mat.define-light-theme(
  (
    color: (
      primary: $ap-primary,
      warn: $ap-danger,
      accent: $ap-danger,
    ),
    typography: $ap-typography,
  )
);
$ap-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $ap-primary,
      warn: $ap-danger,
      accent: $ap-danger,
    ),
    typography: $ap-typography,
  )
);
$main-theme: material-theme-helper.modify-background($ap-theme-light, $white, $white, $dividers, $body, $body);
@include mat.all-component-themes($main-theme);
.mat-tab-group {
  font-family: inherit !important;
}

.mat-tab-label {
  height: 46px !important;
  padding: 0 15px !important;
  cursor: pointer;
  box-sizing: border-box;
  color: #262626;
  opacity: 0.5 !important;
  min-width: 20px !important;
  justify-content: unset !important;
  align-items: unset !important;
  font-weight: 400;

  .mat-tab-label-content::before {
    transition: all 250ms ease;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.9375rem;
    height: 0.0625rem;
    width: calc(100% - 1.875rem);
    font-weight: 400;
    font-size: 0.875rem;
    box-shadow: inset 0px -2px 0px transparent;
  }
}

.mat-tab-label-active {
  color: $title;
  opacity: 1 !important;
  font-weight: 400;

  .mat-tab-label-content::before {
    box-shadow: inset 0px -2px 0px #262626;
  }
}

.mat-ink-bar {
  visibility: hidden !important;
}

.mat-tab-labels::before {
  transition: all 500ms ease;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: calc(100%);
  background: #f0f0f0;
}

.mat-tab-body-content {
  height: unset !important;
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}

.mat-mdc-snack-bar-container {
  button {
    color: $white !important;
    font-weight: 400;
    line-height: 1.375rem;
    padding: 0.1875rem 0.625rem !important;
    background-color: #5d5d5d;
    outline: 0px !important;
  }

  .mdc-snackbar__surface {
    background-color: rgba($title, 0.85);
    color: $white;
    border-radius: 8px !important;
    border: 1px solid $title;
    box-shadow: unset;
    min-width: unset !important;
    min-height: unset !important;
    max-width: unset !important;
    font-weight: 500;
    z-index: 999999;

    .mat-simple-snackbar {
      font-family: unset;
      line-height: unset;
    }
  }
  &.error {
    .mdc-snackbar__surface {
      background-color: rgba($danger, 0.85);
      border: 1px solid $danger;

      button {
        background-color: rgba($danger, 0.55);
      }
    }
  }
}

.labels-tooltip {
  width: 12.0625rem;
  padding: 0.1875rem 0.375rem;
}

.mat-tooltip {
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  margin: 0.4375rem 0px 0px 0rem !important;
  background-color: $title;
  padding: 0.3125rem 0.625rem !important;
  word-break: normal;
  overflow-wrap: anywhere;
}

.mat-tab-body-content,
.mat-drawer-inner-container {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
  }
}

.cdk-overlay-container {
  z-index: calc($appended-to-body-z-index + 1);
}
.center-before {
  &.center-above {
    transform: translate(50%, -16px);
  }
  &.center-below {
    transform: translate(50%, 16px);
  }
}

.mat-menu-content:not(:empty) {
  padding: 8px !important;
}
.mat-menu-item {
  padding: 5px !important;
  height: unset !important;
  line-height: unset !important;
}

.cdk-overlay-pane {
  z-index: calc($appended-to-body-z-index + 1);
}

.mat-tooltip {
  white-space: pre-line !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.625rem 0px 0.8rem !important;
}
.mat-form-field-appearance-outline .mat-form-fielap-flex {
  padding: 0px 0.625rem !important;
}
.mat-form-field-label-wrapper {
  top: -1.2rem !important;
}
.sign-in-form .mat-placeholder-required {
  display: none;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 0.625rem;
}
.auth-card {
  box-shadow: 0 15px 35px #32325d1a, 0 5px 15px #00000012 !important;
  padding: 1.5rem !important;
}
tr.mat-header-row {
  height: initial;
  padding: 14px 0px;
}
th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 10px !important;
}
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 10px !important;
}

th[mat-cell] {
  @include mat.typography-level($ap-typography, "body-2");
}
th[mat-header-cell] {
  @include mat.typography-level($ap-typography, "body-2");
  font-weight: 500;
}

tr[mat-header-row] {
  background: $gray-card;
}
tr.mat-header-row {
  height: 50px !important;
}

.mat-menu-panel {
  min-height: initial !important;
}

.mdc-notched-outline__notch {
  border-right: transparent !important;
}

mat-form-field {
  .mat-mdc-text-field-wrapper {
    background-color: $white !important;
  }
  .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
  }
}
.fullscreen-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100%;
  height: 100%;
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 0px;
  }
}
mat-option.add-auth {
  .mdc-list-item__primary-text {
    flex-grow: 1 !important;
  }
}

.ql-editor {
  padding: 0px !important;
  @include mat.typography-level($ap-typography, "body-1");
  line-height: 1.9rem;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: unset !important;
}

app-mentions-list {
.mat-mdc-menu-item-text{
    display: flex;
    align-items: center;
    width: 100%;
    div {
      flex-grow: 1;
    }
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-bottom: unset;
}

app-collection-builder {
  mat-expansion-panel {
    overflow: visible !important;
  }
}

mat-form-field.\!ap-bg-white {
  .mat-mdc-form-field-focus-overlay {
    background-color: $white !important;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $white !important;
  }
}

.mdc-linear-progress__bar-inner {
  left: 0;
}
.mdc-list-item__primary-text {
  width: 100%;
}
.no-check-bg .mat-button-toggle-checked {
  background-color: $white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: unset;
}
.mat-button-toggle-button {
  display: flex;
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}
.mat-drawer-inner-container {
  overflow: hidden !important;
}

.ap-markdown {
  word-wrap: break-word;
  @include mat.typography-level($ap-typography, "body-1");
}
.mdc-switch--disabled .mdc-switch__icons {
  cursor: not-allowed !important;
}

app-mentions-list .mat-mdc-menu-item {
  overflow: initial !important;
}

app-dashboard-container .mat-drawer {
  background-color: $hover;
}

.mat-placeholder-icon {
  fill: $placeholder !important;
}
.mat-body-icon {
  fill: $body !important;
}
.mat-expansion-panel-content {
  overflow: hidden !important;
}

.data-insertion-search {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: $outline !important;
  }
}

.mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 12px 9px 12px 12px !important;
  transform: scale(0.84);
}
mat-icon[data-mat-icon-name="custom_expand_more"],
mat-icon[data-mat-icon-name="custom_expand_less"] {
  width: 10px;
  height: 10px;
}

.progress-success circle {
  stroke: #209E34 !important;
}
.progress-success-bg circle {
  stroke: #F0F0F0 !important;
}

.material-suffix-icon {
  padding-right:  max(16px, calc(var(--mdc-shape-small, 4px) + 4px));;
}
//Material bug https://github.com/angular/components/issues/26056
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: var(--primary-palette-contrast-500) ;
 }
 
 .mat-mdc-unelevated-button.mat-warn {
   --mdc-filled-button-label-text-color: var(--warn-palette-contrast-500) ;
 }
 
 .mat-mdc-raised-button.mat-primary {
   --mdc-protected-button-label-text-color: var(--primary-palette-contrast-500) ;
 }
 
 .mat-mdc-raised-button.mat-warn {
   --mdc-protected-button-label-text-color: var(--warn-palette-contrast-300) ;
 }
 .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color:  var(--primary-palette-contrast-500);
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color:  var(--primary-palette-contrast-500);
}

.material-suffix-icon {
  padding-right:  max(16px, calc(var(--mdc-shape-small, 4px) + 4px));;
}

ngx-colors-panel { 
  .manual-input-wrapper{
    font-family: $font-family  !important;
    @apply ap-typography-body-2 #{!important};
    p{
      @apply ap-cursor-pointer;
    }
    input{
      font-family: $font-family  !important;
      @apply ap-typography-body-2 #{!important};
    }
  }
}


cdk-virtual-scroll-viewport
{
  /* Hide the original mat-option selected check for single dropdowns, this is because it doesn't get re-rendered when viewport is scrolled, so we show a one we insert manually  */
  mat-option mat-pseudo-checkbox:nth-child(2)
  {
    display: none !important;
  }
  mat-option mat-pseudo-checkbox
  {
    transition:none !important
  }
  
 
}

  

