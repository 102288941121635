//Colors names, these are used in texts and bgs, couldn't use them in material a lot, because their api doesn't allow everything to be overwritten or because some values are derived from others.
$line: #f0f0f0;
$title: #262626;
$dividers: #e0e4e8 !default;
$header: #f5f5f5;
$body: #4f4f4f;
$border: #c2c9d1;
$white: #ffffff;
$gray-card: #fafafa;
$placeholder: #c8c8c8;
$form-label: #7b7b7b;
$black: #1d1d1d;
$disable: #8c8c8c;
$sidebar: #fafbfc;
$hover: #fafafa;
$avatar: #515151;
$blue-link: #1890ff;
$gray-select: #f5f5f5;
$add-piece: #a6b1bf;
$outline: rgba(0, 0, 0, 0.12);
$description: #8c8c8c;
$bleached-gray: #a6b1bf;
$danger: #dc3545;
$primary-color: (
    "default": #6e41e2,
    "dark": #6838e0,
    "light": #eee9fc,
    "medium": #c5b3f3,
);
$warn-color: (
    "default": #f78a3b,
    "light": #fff6e4,
    "dark": #cc8805,
    "medium": #f0d6a1,
);
$success-color: (
    "default": #14ae5c,
    "light": #3cad71,
);
$font-family: "Open Sans, Noto Sans Arabic";
$selection-color: #8965e6;
//Colors end
$drop-point-height: 125px;
$drop-point-width: 300px;
$appended-to-body-z-index: 9999;
$knob-width: 3px;
$knob-height: 24px;
$dot-size: 2.5px;
$dot-space: 20px;